img {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

.filter-container {
  margin-bottom: 12px;
  .filter-item {
    label {
      font-weight: 500;
      font-size: 15px;
    }

    &.btn {
      button {
        position: relative;
        top: 12px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-layout-sider {
    position: fixed;
    z-index: 999;
  }

  .site-layout {
    margin-left: 0 !important;
  }

  .ant-layout-sider-collapsed {
    width: 0 !important;
    min-width: 0 !important;
    z-index: -1;
  }

  .ant-layout-content {
    margin: 0 !important;
  }

  .service-duration {
    text-align: right;
  }

  .filter-container {
    .filter-item {
      &.mobile-no-top button {
        top: 0;
      }
    }
  }

  .sms-wrapper {
    padding: 0 !important;
  }
}
