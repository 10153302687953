@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

:root {
  --radius: 0.625rem;
  --input-radius: 0.475rem;
}

//Custom theme

html,
body {
  font-family: Poppins, Helvetica, "sans-serif";
  -webkit-font-smoothing: antialiased;
}

b {
  font-weight: 600 !important;
}

.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: #1e1e2d;
}

.ant-layout {
  background: #f7f7f8 !important;
}

header.ant-layout-header {
  padding: 0px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
}

input.ant-input,
input.ant-input-password {
  background-color: #f5f8fa;
  border-color: #f5f8fa;
  color: #686c80;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-weight: 500;
  border-radius: var(--input-radius);
}

.ant-table-thead > tr > th {
  background-color: #f5f8fa !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: var(--radius) !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: var(--radius) !important;
}

.ant-tag {
  font-weight: 800;
  padding: 2px 10px !important;
  border-radius: 5px !important;
  border: none !important;

  &.ant-tag-green {
    color: #50cd89 !important;
    background-color: #e8fff3 !important;
    border-color: #e8fff3 !important;
  }

  &.ant-tag-cyan {
    color: #009ef7 !important;
    background-color: #f1faff !important;
    border-color: #f1faff !important;
  }
}

.ant-input-password {
  padding: 0 0.5em !important;
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  border-radius: var(--input-radius) !important;
}

.ant-input-number {
  overflow: hidden;
  border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
}

.ant-input-group-addon,
.ant-input-number-group-addon {
  border: none !important;
  border-radius: var(--input-radius) 0 0 var(--input-radius) !important;
  background-color: #11161930 !important;
}

.ant-input-group-addon:last-child {
  // padding: 0;
  border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
}

.ant-form-item-has-error {
  // background-color: #f5f8fa;
}

input::placeholder {
  color: #878a98 !important;
}

.box {
  background-color: #fff;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 2em;
  border-radius: var(--radius) !important;
}

.ant-form-item-label label {
  font-weight: 500;
}

.ant-breadcrumb a {
  color: #e3e3e373 !important;
}

.ant-breadcrumb-separator {
  color: #e3e3e373 !important ;
}

.textarea.ant-input {
  border-radius: 5px;
}

.ant-breadcrumb > span {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
}

.ant-breadcrumb > span:last-child {
  font-size: 18px;
  font-weight: 600;
}

//form
.ant-form-item.form-label-center {
  .ant-form-item-label {
    text-align: center;
  }
}

.ant-select-selector {
  height: 40px !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
  color: #5e6278 !important;

  .ant-select-selection-item {
    font-weight: 500 !important;
  }
}

// .ant-modal-content {
.ant-btn,
input,
.ant-select-selector.custom,
.custome-select .ant-select-selector {
  height: 40px !important;
}

.custome-select .ant-select-selector {
  padding: 5px 10px !important;
}
.ant-select-selector.custom {
  padding-top: 5px !important;
}

//Custom theme

html,
body {
  font-family: Poppins, Helvetica, "sans-serif" !important;
  -webkit-font-smoothing: antialiased;
}

.ant-select-selector,
.ant-picker,
.ant-btn {
  border-radius: var(--input-radius) !important;
  font-weight: 500;
  background-color: #eef3f7 !important;
  border-color: #eef3f7 !important;
  color: #5e6278 !important;
}

.ant-picker {
  input {
    font-weight: 500;
    color: #5e6278 !important;
  }
}

// End custom theme
.ant-modal {
  input.ant-input,
  input.ant-input-password {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
    color: #686c80;
    transition: color 0.2s ease, background-color 0.2s ease;
    font-weight: 500;
    border-radius: var(--input-radius);
  }

  .ant-select-selector,
  .ant-picker {
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-input-password {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
  }
}

.ant-upload-list-item-card-actions-btn {
  border: none !important;
  background-color: transparent !important;
}

.ant-btn-primary {
  color: #fff !important;
  background-color: #1890ff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  -webkit-transition: all 0.3s;
  font-weight: 500;
  font-size: 15px;
  padding: 0 15px;

  transition: all 0.3s;

  &:hover {
    // background-color: #4f5b6e !important;
    border-color: #4f5b6e;
    color: white;
  }
}

.ant-btn-dangerous {
  color: #f1416c !important;
  border-color: #ffdfdf !important;
  background-color: #ffdfdf !important;

  &:hover {
    color: #ffffff !important;
    border-color: #f1416c !important;
    background-color: #f1416c !important;
  }
}

.ant-pagination {
  li,
  .ant-pagination-item-link {
    border: none !important;
  }

  input {
    height: 30px !important;
    text-align: center;
    border-radius: var(--input-radius) !important;
  }

  li a:hover,
  .ant-pagination-item-link:hover {
    z-index: 2;
    color: #009ef7;
    text-decoration: none;
    background-color: #f1faff;
    border-color: #009ef7;
    border-radius: 5px;
  }

  .ant-pagination-item-active {
    background-color: #009ef7;
    color: #fff !important;
    border-radius: 5px;

    a {
      color: #fff;
    }

    a:hover {
      background-color: #009ef7;
      color: #fff !important;
    }
  }
}

.ant-btn-primary[disabled] {
  color: #fff !important;
}

// .ant-btn.ant-btn-primary.ant-btn-dangerous {
//   border-color: #ff4d4f !important;
//   background: #ff4d4f !important;
// }

.ant-menu-item,
.ant-menu-submenu-title {
  font-weight: 600;
}

.ant-btn-link {
  color: #1890ff !important;
}
